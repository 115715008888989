html {
    box-sizing: border-box;
    font-size: 16px;

    --formWidth: 500px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
    font-family: "impact";
    font-weight: normal;
    color: white;
}

ol,
ul {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

/* styles */
.app {
    padding: 50px;
    width: 100%;
    min-height: 100vh;
    display: grid;
    justify-items: center;
    place-content: center;
    row-gap: 40px;
    background: black;
}

.formComp {
    max-width: 80vw;
    width: 80vw;
}

.formWrapper {
    padding: 30px 20px;
    background-color: white;
    box-shadow: 0 0 1.5rem rgba(105, 105, 105, 0.5);
    border-radius: 4px;
    margin-bottom: 30px;
    width: 30vw;
}

.baseFormHeading {
    text-transform: capitalize;
    color: black;
}

.baseForm {
    display: grid;
    row-gap: 30px;
}

.formRow,
.formFieldWrap {
    display: grid;
}

.formRow,
.formFieldWrap {
    grid-template-columns: 1fr auto;
}

.formRow.col-2 {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
}

.formFieldLabel {
    font-weight: 500;
    margin-bottom: 5px;
    color: black;
}

.formFieldWrapInner {
    grid-column: 1/-1;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    background-color: white;
    margin: 10px 0 5px;
    margin-top: 10px;
    border-radius: 4px;
    font-size: 14px;
    height: 31px;
    display: flex;

    transition: background-color 240ms, box-shadow 240ms;
}

.formFieldWrapInner.active {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.34) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

[data-show-error="true"] {
    color: red;
}

.formField {
    width: 100%;
    height: 100%;
    border: none;
    padding: 5px 10px;
    font-size: inherit;
    font-weight: 400;
    font-family: inherit;
    border-radius: inherit;
}

.formFieldLabel::first-letter {
    text-transform: uppercase;
}

.passwordCue,
.errorMessage {
    color: #686868;
    font-size: 16px;
}

.errorMessage {
    color: tomato;
    text-align: left;
}
.successMessage {
    color: green;
    text-align: left;
}

.passwordCue {
    grid-column: 1/-1;
}

button[type="submit"] {
    background-color: #ffc600  !important;
    font-family: Impact, serif;
    color: black  !important;
    cursor: pointer;
    padding: 10px 0px;
    border-radius: 4px;
    border: 1px solid #ffc600;
    font-size: 16px;
    text-transform: capitalize;
    grid-column: 1/-1;
}

/* Console */
.console {
    background-color: whitesmoke;
    max-width: 500px;
    width: 500px;
    padding: 30px 20px;
    background-color: white;
    box-shadow: 0 0 1.5rem rgba(105, 105, 105, 0.5);
    border-radius: 4px;
    max-width: 80vw;
    overflow: scroll;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pageFooter {
    position: fixed;
    bottom: 0;
    /* padding: 30px 20px; */
    background-color: white;
    /* border-top: 1px solid #393939; */
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 20px;
}

.pageFooter a {
    text-decoration: none;
    color: #393939;
    /* background-color: var(--yellow, #ffc600); */
    /* padding: 5px; */
}

.formInfoWrapper {
    max-width: 500px;
}

.formInfoCue {
    font-weight: bold;
    text-transform: uppercase;
    background-color: #ffc600;
    padding: 5px;
    margin-bottom: 10px;
    display: inline-block;
}

.formInfo {
    line-height: 1.5;
    max-width: 80vw;
}


/* Style the header with a grey background and some padding */
.header {
    overflow: hidden;
    background-color: #f1f1f1;
    padding: 20px 10px;
  }
  
/* Style the header links */
.header a {
    float: left;
    color: black;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
}
.header .nav-link {
    float: left;
    color: black;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.header a.logo {
    font-size: 25px;
    font-weight: bold;
}

/* Change the background color on mouse-over */
.header a:hover {
    background-color: #ddd;
    color: black;
}
.header .nav-link:hover {
    background-color: #ddd;
    color: black;
}

/* Style the active/current link*/
.header a.active {
    background-color: dodgerblue;
    color: white;
}

/* Float the link section to the right */
.header-right {
    float: right;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 500px) {
    .header a {
        float: none;
        display: block;
        text-align: left;
    }
    .header-right {
        float: none;
    }
}
@media screen and (max-width: 700px) {

    .formWrapper{
        width: 80vw;
    }
}


/* card */

.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */
  .container {
    padding: 2px 16px;
  }

  .logo {
      max-width: 3%;
      height: auto;
  }

  a {
      color: #ffc600;
  }

  /* Firebase UI */

.firebaseui-form-actions button.firebaseui-id-submit{
    background-color: #ffc600 !important;
    font-family: Impact, serif;
    color: black !important; 
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #ffc600;
    font-size: 16px;
    text-transform: capitalize;
    grid-column: 1/-1;
}